<template>
  <div class="category-filter">
    <input type="radio" id="TOUS" name="radio-group" @change="selectTOUS" checked />
    <label for="TOUS">TOUS</label>
    <input type="radio" id="DEBUTANTS" name="radio-group" @change="selectDEBUTANTS" />
    <label for="DEBUTANTS">DEB</label>
    <input type="radio" id="LOISIRS" name="radio-group" @change="selectLOISIRS" />
    <label for="LOISIRS">LOIS</label>
    <input type="radio" id="CONFIRMES" name="radio-group" @change="selectCONFIRMES" />
    <label for="CONFIRMES">CONF</label>
    <input type="radio" id="OPEN" name="radio-group" @change="selectOPEN" />
    <label for="OPEN">OPEN</label>
  </div>
</template>

<script>

export default {
  props: ['selectedCategory'],
  data() {
    return {
      selected: this.selectedCategory
    };
  },
  methods: {
    selectTOUS() {
      this.$emit('categoryChange', "TOUS");
    },
    selectDEBUTANTS() {
      this.$emit('categoryChange', "DÉBUTANTS");
    },
    selectLOISIRS() {
      this.$emit('categoryChange', "LOISIRS");
    },
    selectCONFIRMES() {
      this.$emit('categoryChange', "CONFIRMÉS");
    },
    selectOPEN() {
      this.$emit('categoryChange', "OPEN");
    }
  }
};
</script>

<style>

.category-filter {
  margin-top: 20px;
  margin-bottom: 20px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 5px;
  margin: 2px;
}

[type="radio"]:not(:checked)+label {
  border: 1px solid #dfdfd7;
  color: #9b9b94;
  background: #e9eae4;
}

[type="radio"]:not(:checked)+label:hover {
  background: #eee;
}

[type="radio"]:checked+label {
  background: #369ff4;
  border-color: #369ff4;
  color: #fff;
}

</style>
