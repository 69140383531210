<template>
  <div class="team-list">
    <li v-for="team in teams" :key="team.id" @click="selectTeam(team)">
      {{ team.name }} ({{ team.category }})
    </li>
  </div>
</template>

<script>
export default {
  props: ['teams'],
  methods: {
    selectTeam(team) {
      this.$emit('selectTeam', team);
    }
  }
};
</script>

<style>

.team-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  max-height: 40vh;
  overflow: scroll;
}

.team-list li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
}

.team-list li:hover {
  background-color: #d0d0d0;
}

</style>
