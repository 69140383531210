<template>
  <div id="app">
    <div id="title"><strong>Tournoi du {{ date }}</strong></div>
    <CategoryFilter :selectedCategory="selectedCategory" @categoryChange="filterByCategory" />
    <SearchBar @search="filterBySearch" />
    <TeamList :teams="filteredTeams" @selectTeam="selectTeam" />
    <TeamDetails v-if="selectedTeam" :team="selectedTeam" @updateTeam="updateTeam" />
  </div>
</template>

<script>
import CategoryFilter from './components/CategoryFilter.vue';
import SearchBar from './components/SearchBar.vue';
import TeamList from './components/TeamList.vue';
import TeamDetails from './components/TeamDetails.vue';

const WS_URL = 'wss://tournois.inscription.wss.home.nigoflo83.ovh';

export default {
  data() {
    return {
      ws: null,
      date: null,
      teams: [],
      filteredTeams: [],
      selectedTeam: null,
      selectedCategory: 'TOUS',
      searchQuery: ''
    };
  },
  components: {
    CategoryFilter,
    SearchBar,
    TeamList,
    TeamDetails
  },
  methods: {
    filterByCategory(category) {
      this.selectedCategory = category;
      this.applyFilters();
    },
    filterBySearch(query) {
      this.searchQuery = query;
      this.applyFilters();
    },
    applyFilters() {
      let ft = this.teams.filter(team => {
        return ( this.selectedCategory === 'TOUS' || team.category === this.selectedCategory ) &&
               team.name.toLowerCase().includes(this.searchQuery.toLowerCase() );
      });
      
      // Tri de la liste
      ft = ft.sort((a, b) => {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
          return -1;
        }
      });
      let id = 0;
      for ( let t of ft ) {
        t.id = id;
        id++;
      }
      this.filteredTeams = ft;

      // Si l'équipe sélectionnée n'est plus dans la liste
      if ( ! this.filteredTeams.includes(this.selectedTeam) ) {
        this.selectedTeam = null;
      }
    },
    selectTeam(team) {
      if ( team !== this.selectedTeam ) {
        this.selectedTeam = null;
        setTimeout(() => { this.selectedTeam = team; }, 50);
      }
    },
    updateTeam(updatedTeam) {
      console.log(JSON.stringify(updatedTeam));
      if ( this.ws.readyState === 1 ) {
        let messageData = {
          type: "REGISTER_TEAM",
          team: updatedTeam
        };
        this.ws.send(JSON.stringify(messageData));
        this.selectedTeam = null;
      }
    },
    tryConnect() {
      // Initialisation de la websocket
      if ( ( this.ws === null ) ||
           ( this.ws.readyState !== 1 ) ) {
        console.log('Trying to connect to the server ' + WS_URL);
        this.ws = new WebSocket(WS_URL);

        this.ws.onmessage = (message) => {
          let data = JSON.parse(message.data);
          this.date = data.date;
          let rt = data.pre_registered_teams;
          this.teams = rt.filter(team => !team.registered);
          this.applyFilters();
        };
        this.ws.onopen = () => {
          console.log('Connected to the server');
          let messageData = {
            type: "GET_DATA"
          };
          this.ws.send(JSON.stringify(messageData));
        };
        this.ws.onclose = () => {
          console.log('Disconnected from the server');
          setTimeout(this.tryConnect, 2000);
        };
      }
    }
  },
  mounted() {
    this.tryConnect();
  }
};
</script>

<style>

#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 800px;
  margin: 0 auto;
  padding: 5px;
}

#title {
  width: 100%;
  color: #fff;
  background: #369ff4;
  padding: 4px;
}

</style>
