<template>
  <div class="team-details">
    <div class="team-details-name">
      <strong>Nom</strong> {{ team.name }}
    </div>
    <div class="team-details-cat">
      <strong>Cat.</strong> {{ team.category }}
    </div>
    <div class="team-details-bonus">
      <strong for="bonus">B/M</strong>
      <button @click="decrementBonus(localTeam)" class="incdec" id="dec">-</button>
      <input type="number" id="bonus" v-model.number="localTeam.bonus_malus" />
      <button @click="incrementBonus(localTeam)" class="incdec" id="inc">+</button>
    </div>
    <div class="team-details-new-name">
      <strong for="new-name">Nouv.</strong>
      <input type="text" id="new-name" v-model="localTeam.new_name" />
    </div>
    <button class="team-details-button" @click="validateTeam">VALIDER L'INSCRIPTION</button>
  </div>
</template>

<script>
export default {
  props: ['team'],
  data() {
    return {
      localTeam: { ...this.team }
    };
  },
  methods: {
    incrementBonus(team) {
      team.bonus_malus++;
    },
    decrementBonus(team) {
      team.bonus_malus--;
    },
    validateTeam() {
      this.localTeam.registered = true;
      console.log(this.localTeam);
      this.$emit('updateTeam', this.localTeam);
    }
  }
};
</script>

<style>

.team-details {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.team-details-name {
  text-align: start;
  margin-left: 1%;
  margin-bottom: 1%;
}

.team-details-name > strong {
  display: inline-block;
  min-width: 3rem;
  margin-right: 10px;
  height: 25px;
}

.team-details-cat {
  text-align: start;
  margin-left: 1%;
  margin-bottom: 1%;
}

.team-details-cat > strong {
  display: inline-block;
  min-width: 3rem;
  margin-right: 10px;
  height: 25px;
}

.team-details-bonus {
  text-align: start;
  margin-left: 1%;
  margin-bottom: 10px;
}

.team-details-bonus > strong {
  display: inline-block;
  min-width: 3rem;
  margin-right: 10px;
}

.incdec {
  display: inline-block;
  width: 30px;
  height: 25px;
  margin-bottom: 0px;
}

#dec {
  margin-right: 10px;
}

#inc {
  margin-left: 10px;
}

.team-details-bonus > input {
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-bottom: 0px;
}

.team-details-new-name {
  text-align: start;
  margin-left: 1%;
  margin-bottom: 20px;
}

.team-details-new-name > strong {
  display: inline-block;
  min-width: 3rem;
  margin-right: 10px;
}

.team-details-new-name > input {
  width: 50%;
  height: 25px;
  margin-bottom: 0px;
}

.team-details-button {
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 15px;
  width: 70%;
}

button:hover {
  background-color: #0056b3;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

</style>
