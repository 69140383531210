<template>
  <div class="input-container">
    <input v-model="text" type="text" placeholder="Chercher une équipe" @input="updateSearchQuery"/>
    <button v-if="text" @click="clearText" class="clear-button">✖</button>
  </div>
</template>

<script>

export default {
  data() {
    return {
      text: ''
    };
  },
  methods: {
    clearText() {
      this.text = '';
      this.$emit('search', event.target.value);
    },
    updateSearchQuery(event) {
      this.$emit('search', event.target.value);
    }
  }
};

</script>

<style>

.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

input {
  padding-right: 24px; /* espace pour le bouton de suppression */
  height: 25px;
  width: 80%;
}

.clear-button {
  position: absolute;
  right: 9%;
  top: 45%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

</style>
